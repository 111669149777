import React, { useState, useEffect } from 'react';
import { MapPin } from 'lucide-react';
import { supabase } from '../config/client';
import { useAuth } from '../context/AuthContext';

const AddressSelection = ({ onAddressSelect, selectedAddressId }) => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNew, setShowAddNew] = useState(false);
  const [newAddress, setNewAddress] = useState({
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    pincode: '',
    gps_location: null
  });

  const { user } = useAuth();

  useEffect(() => {
    if (user?.id) {
      fetchUserAddresses(user.id);
    }
  }, [user]);

  const fetchUserAddresses = async (userId) => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from('addresses')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      setAddresses(data || []);
    } catch (err) {
      console.error('Error fetching addresses:', err);
      setError('Failed to load addresses. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setNewAddress(prev => ({
            ...prev,
            gps_location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          }));
        },
        (error) => {
          console.error('Error getting location:', error);
          setError('Failed to get location. Please try again.');
        }
      );
    }
  };

  const handleAddressInput = (field, value) => {
    setNewAddress(prev => ({
      ...prev,
      [field]: value
    }));
    setError(null);
  };

  const handleSaveAddress = async () => {
    try {
      if (!user?.id) {
        setError('Please login to save address');
        return;
      }

      const requiredFields = ['address_line1', 'city', 'state', 'pincode'];
      const missingFields = requiredFields.filter(field => !newAddress[field]);
      
      if (missingFields.length > 0) {
        setError('Please fill in all required fields');
        return;
      }

      if (newAddress.pincode.length !== 6) {
        setError('Please enter a valid 6-digit pincode');
        return;
      }

      setError(null);

      const addressData = {
        user_id: user.id,
        name: null,
        address_line1: newAddress.address_line1.trim(),
        address_line2: newAddress.address_line2?.trim() || null,
        city: newAddress.city.trim(),
        state: newAddress.state.trim(),
        pincode: newAddress.pincode,
        address_type: null,
        is_default: false,
        gps_location: newAddress.gps_location,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      delete addressData.id;

      const { data, error } = await supabase
        .from('addresses')
        .insert([addressData])
        .select()
        .single();

      if (error) {
        throw error;
      }

      setAddresses(prev => [data, ...prev]);
      onAddressSelect(data.id);  // Modified to pass only the ID
      
      setNewAddress({
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        pincode: '',
        gps_location: null
      });
      setShowAddNew(false);

    } catch (err) {
      console.error('Error saving address:', err);
      setError(err.message || 'Failed to save address. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-8 h-8 border-2 border-[#73001C] border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="p-4 rounded-lg bg-red-50 text-red-700">
        Please log in to manage addresses.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">Select Delivery Address</h2>
      
      {error && (
        <div className="p-4 rounded-lg bg-red-50 text-red-700 text-sm">
          {error}
        </div>
      )}
      
      <div className="space-y-3">
        {addresses.length === 0 ? (
          <div className="text-center py-6 text-gray-500">
            <p>No saved addresses found.</p>
            <p className="text-sm mt-1">Add a new address to continue.</p>
          </div>
        ) : (
          addresses.map((address) => (
            <div
              key={address.id}
              onClick={() => onAddressSelect(address.id)}  // Modified to pass only the ID
              className={`
                p-4 rounded-lg border-2 cursor-pointer transition-all
                ${selectedAddressId === address.id
                  ? 'border-[#73001C] bg-red-50'
                  : 'border-gray-200 hover:border-gray-300'
                }
              `}
            >
              <div className="flex items-start space-x-3">
                <MapPin className="h-5 w-5 text-gray-400 mt-1" />
                <div>
                  <p className="text-sm text-gray-500">{address.address_line1}</p>
                  {address.address_line2 && (
                    <p className="text-sm text-gray-500">{address.address_line2}</p>
                  )}
                  <p className="text-sm text-gray-500">
                    {address.city}, {address.state} - {address.pincode}
                  </p>
                </div>
              </div>
            </div>
          ))
        )}

        <button
          onClick={() => setShowAddNew(true)}
          className="w-full p-4 rounded-lg border-2 border-dashed border-gray-300 hover:border-gray-400 flex items-center justify-center space-x-2 text-gray-600 hover:text-gray-700 transition-colors"
        >
          <span className="text-2xl">+</span>
          <span>Add New Address</span>
        </button>
      </div>

      {showAddNew && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md space-y-4 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center sticky top-0 bg-white pb-4">
              <h3 className="text-lg font-semibold">Add New Address</h3>
              <button 
                onClick={() => {
                  setShowAddNew(false);
                  setError(null);
                  setNewAddress({
                    address_line1: '',
                    address_line2: '',
                    city: '',
                    state: '',
                    pincode: '',
                    gps_location: null
                  });
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Address Line 1 <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={newAddress.address_line1}
                  onChange={(e) => handleAddressInput('address_line1', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                  placeholder="Street address"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Address Line 2
                </label>
                <input
                  type="text"
                  value={newAddress.address_line2}
                  onChange={(e) => handleAddressInput('address_line2', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                  placeholder="Apartment, suite, etc. (optional)"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    City <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={newAddress.city}
                    onChange={(e) => handleAddressInput('city', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    State <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={newAddress.state}
                    onChange={(e) => handleAddressInput('state', e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Pincode <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={newAddress.pincode}
                  onChange={(e) => handleAddressInput('pincode', e.target.value.replace(/\D/g, '').slice(0, 6))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                  placeholder="6-digit pincode"
                  maxLength={6}
                />
              </div>

              <button
                onClick={handleGetLocation}
                className="w-full p-2 border-2 border-gray-300 rounded-lg hover:border-gray-400 text-gray-600 hover:text-gray-700"
              >
                {newAddress.gps_location ? 'Location Captured ✓' : 'Get Current Location'}
              </button>

              <button
                onClick={handleSaveAddress}
                className="w-full p-2 bg-[#73001C] hover:bg-[#5a0016] text-white rounded-lg transition-colors"
              >
                Save Address
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressSelection;