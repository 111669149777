import { useState, useEffect } from 'react';
import { supabase } from '../config/client';

export const useOrders = (userId) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!userId) return;

      // First, fetch orders
      const { data: ordersData, error: ordersError } = await supabase
        .from('orders')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (ordersError) throw ordersError;

      // Get unique tailor IDs
      const tailorIds = [...new Set(ordersData.map(order => order.tailor_id))].filter(Boolean);

      // Fetch tailor details for all tailor IDs
      let tailorsMap = {};
      if (tailorIds.length > 0) {
        const { data: tailorsData, error: tailorsError } = await supabase
          .from('employees')
          .select('*')
          .in('id', tailorIds);

        if (tailorsError) throw tailorsError;

        tailorsMap = tailorsData.reduce((acc, tailor) => {
          acc[tailor.id] = tailor;
          return acc;
        }, {});
      }

      // Combine orders with tailor details
      const ordersWithTailors = ordersData.map(order => ({
        ...order,
        tailor: tailorsMap[order.tailor_id] || null
      }));

      setOrders(ordersWithTailors);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching orders:', err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchOrders();
    }
  }, [userId]);

  return { orders, loading, error, fetchOrders };
};