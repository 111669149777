/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import { Edit2 } from 'lucide-react';
import { useEnhancePages } from '../components/Modals';
import { AddCardModal } from '../components/AddCardModal';
import { DeleteAccountModal } from '../components/DeleteAccountModal';
import { Toast } from '../components/Toast';
import Navbar from '../components/Navbar';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../config/client';

// Personal Information Page
export const PersonalInformation = () => {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    phoneVerified: false,
    emailVerified: false,
    isBusiness: false,
    businessName: '',
    gstNumber: '',
    defaultAddressId: null,
    gender: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data, error } = await supabase
          .from('user_meta')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        setFormData({
          fullName: data.full_name || '',
          phoneNumber: data.phone_number || '',
          email: data.email || '',
          phoneVerified: data.phone_verified || false,
          emailVerified: data.email_verified || false,
          isBusiness: data.is_business || false,
          businessName: data.business_name || '',
          gstNumber: data.gst_number || '',
          defaultAddressId: data.default_address_id || null,
          gender: data.gender || '',
        });
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(err.message);
      }
    };

    if (user) {
      fetchUserData();
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from('user_meta')
        .update({
          full_name: formData.fullName,
          phone_number: formData.phoneNumber,
          email: formData.email,
          phone_verified: formData.phoneVerified,
          email_verified: formData.emailVerified,
          is_business: formData.isBusiness,
          business_name: formData.businessName,
          gst_number: formData.gstNumber,
          default_address_id: formData.defaultAddressId,
          gender: formData.gender,
          updated_at: new Date().toISOString(),
        })
        .eq('id', user.id)
        .select();

      if (error) throw error;

      console.log('Updated user data:', data);
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBusinessToggle = (e) => {
    setFormData(prev => ({
      ...prev,
      isBusiness: e.target.checked,
      businessName: e.target.checked ? prev.businessName : '',
      gstNumber: e.target.checked ? prev.gstNumber : '',
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Personal Information</h1>
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="flex items-center px-4 py-2 text-sm font-medium text-[#800020] hover:bg-[#800020]/5 rounded-lg transition-colors"
              disabled={loading}
            >
              <Edit2 className="h-4 w-4 mr-2" />
              {isEditing ? 'Cancel' : 'Edit'}
            </button>
          </div>

          {error && (
            <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  disabled={!isEditing || loading}
                  value={formData.fullName}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  disabled={!isEditing || loading}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
                {formData.phoneVerified && (
                  <span className="text-sm text-green-600 mt-1">✓ Verified</span>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  disabled={!isEditing || loading}
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
                {formData.emailVerified && (
                  <span className="text-sm text-green-600 mt-1">✓ Verified</span>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Gender</label>
                <select
                  name="gender"
                  disabled={!isEditing || loading}
                  value={formData.gender}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            {isEditing && (
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-lg"
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] rounded-lg transition-colors disabled:opacity-50"
                  disabled={loading}
                >
                  {loading ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

// Privacy & Security Page
export const PrivacyAndSecurity = () => {
  const { user } = useAuth();
  const {
    showDeleteAccount,
    setShowDeleteAccount,
    toast,
    setToast
  } = useEnhancePages();
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError("New passwords don't match");
      setLoading(false);
      return;
    }

    try {
      const { data, error: updateError } = await supabase.auth.updateUser({
        password: passwordData.newPassword
      });

      if (updateError) throw updateError;

      setToast({
        type: 'success',
        message: 'Password updated successfully'
      });
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Password Section */}
          <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Password</h2>
            {error && (
              <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-lg">
                {error}
              </div>
            )}
            <form onSubmit={handleUpdatePassword} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Current Password</label>
                <input
                  type="password"
                  value={passwordData.currentPassword}
                  onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
                  disabled={loading}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">New Password</label>
                <input
                  type="password"
                  value={passwordData.newPassword}
                  onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                  disabled={loading}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                <input
                  type="password"
                  value={passwordData.confirmPassword}
                  onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
                  disabled={loading}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] rounded-lg transition-colors disabled:opacity-50"
                  disabled={loading}
                >
                  {loading ? 'Updating...' : 'Update Password'}
                </button>
              </div>
            </form>
          </div>

          {/* Two-Factor Authentication */}
          <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Two-Factor Authentication</h2>
            <p className="text-gray-600 mb-4">Add an extra layer of security to your account.</p>
            <button 
              className="px-4 py-2 text-sm font-medium text-[#800020] border border-[#800020] hover:bg-[#800020]/5 rounded-lg transition-colors"
              onClick={() => setToast({
                type: 'info',
                message: '2FA feature coming soon!'
              })}
            >
              Enable 2FA
            </button>
          </div>

          {/* Delete Account */}
          <div className="bg-white rounded-xl shadow-lg p-6 border border-red-200">
            <h2 className="text-xl font-bold text-red-600 mb-4">Delete Account</h2>
            <p className="text-gray-600 mb-4">
              Once you delete your account, there is no going back. Please be certain.
            </p>
            <button
              onClick={() => setShowDeleteAccount(true)}
              className="px-4 py-2 text-sm font-medium text-red-600 border border-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              Delete Account
            </button>
          </div>
        </div>

        <DeleteAccountModal 
          isOpen={showDeleteAccount} 
          onClose={() => setShowDeleteAccount(false)} 
        />
      
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
      </div>
    </div>
  );
};

// Payment Methods Page
export const PaymentMethods = () => {
  const { user } = useAuth();
  const {
    showAddCard,
    setShowAddCard,
    toast,
    setToast
  } = useEnhancePages();

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCards = async () => {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('payment_methods')
          .select('*')
          .eq('user_id', user.id);

        if (error) throw error;
        setCards(data || []);
      } catch (error) {
        console.error('Error fetching payment methods:', error);
        setToast({
          type: 'error',
          message: 'Failed to load payment methods'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, [user]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Payment Methods</h1>
            <button 
              onClick={() => setShowAddCard(true)}
              className="flex items-center px-4 py-2 text-sm font-medium text-[#800020] hover:bg-[#800020]/5 rounded-lg transition-colors"
            >
              <Edit2 className="h-4 w-4 mr-2" />
              Add New Card
            </button>
          </div>

          {loading ? (
            <div className="flex items-center justify-center h-32">
              <div className="w-8 h-8 border-4 border-[#800020] border-t-transparent rounded-full animate-spin" />
            </div>
          ) : cards.length > 0 ? (
            <div className="space-y-4">
              {cards.map((card) => (
                <div key={card.id} className="flex items-center justify-between p-4 border border-gray-200 rounded-lg">
                  <div className="flex items-center">
                    <div className="w-12 h-8 bg-gray-100 rounded flex items-center justify-center mr-4">
                      {card.brand === 'visa' ? 'Visa' : 'MC'}
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        •••• {card.last4}
                      </p>
                      <p className="text-sm text-gray-500">
                        Expires {card.exp_month}/{card.exp_year}
                      </p>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <button 
                      className="p-2 text-gray-400 hover:text-[#800020] rounded-lg transition-colors"
                      onClick={() => setShowAddCard(true)}
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button 
                      className="p-2 text-gray-400 hover:text-red-600 rounded-lg transition-colors"
                      onClick={async () => {
                        try {
                          const { error } = await supabase
                            .from('payment_methods')
                            .delete()
                            .eq('id', card.id);

                          if (error) throw error;

                          setCards(cards.filter(c => c.id !== card.id));
                          setToast({
                            type: 'success',
                            message: 'Payment method removed successfully'
                          });
                        } catch (error) {
                          console.error('Error removing payment method:', error);
                          setToast({
                            type: 'error',
                            message: 'Failed to remove payment method'
                          });
                        }
                      }}
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-500">No payment methods added yet.</p>
            </div>
          )}
        </div>

        <AddCardModal 
          isOpen={showAddCard} 
          onClose={() => setShowAddCard(false)}
          onSuccess={async (cardData) => {
            try {
              const { data, error } = await supabase
                .from('payment_methods')
                .insert({
                  user_id: user.id,
                  ...cardData
                })
                .select()
                .single();

              if (error) throw error;

              setCards([...cards, data]);
              setShowAddCard(false);
              setToast({
                type: 'success',
                message: 'Payment method added successfully'
              });
            } catch (error) {
              console.error('Error adding payment method:', error);
              setToast({
                type: 'error',
                message: 'Failed to add payment method'
              });
            }
          }}
        />
      
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
      </div>
    </div>
  );
};

// My Profile Page
export const MyProfile = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        setProfileData(data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF] flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-[#800020] border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">My Profile</h1>
          
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <div className="w-24 h-24 bg-[#800020] rounded-full flex items-center justify-center text-white text-3xl">
                {user?.user_metadata?.first_name?.[0]?.toUpperCase() || user?.email?.[0]?.toUpperCase()}
              </div>
              <div>
                <h2 className="text-xl font-semibold">
                  {user?.user_metadata?.first_name} {user?.user_metadata?.last_name}
                </h2>
                <p className="text-gray-500">{user?.email}</p>
              </div>
            </div>

            {profileData && (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <h3 className="text-lg font-medium mb-2">Account Details</h3>
                  <div className="space-y-2 text-gray-600">
                    <p>Member since: {new Date(profileData.created_at).toLocaleDateString()}</p>
                    <p>Last updated: {new Date(profileData.updated_at).toLocaleDateString()}</p>
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Contact Information</h3>
                  <div className="space-y-2 text-gray-600">
                    <p>Phone: {profileData.phone || 'Not provided'}</p>
                    <p>Address: {profileData.address || 'Not provided'}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default {
  PersonalInformation,
  PrivacyAndSecurity,
  PaymentMethods,
  MyProfile
};