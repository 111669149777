import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../config/client';
import {
  X,
  Eye,
  EyeOff,
  Mail,
  Lock,
  User,
  Phone,
  Scissors,
  CheckCircle,
} from 'lucide-react';

const EmailVerificationModal = ({ isOpen, onClose, email }) => {
  const [isResending, setIsResending] = useState(false);
  const [resendStatus, setResendStatus] = useState(null);

  const handleResendVerification = async () => {
    setIsResending(true);
    setResendStatus(null);
    
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: email
      });
      
      if (error) throw error;
      
      setResendStatus({
        type: 'success',
        message: 'Verification email sent! Please check your inbox.'
      });
    } catch (error) {
      setResendStatus({
        type: 'error',
        message: error.message || 'Failed to resend verification email'
      });
    } finally {
      setIsResending(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative w-full max-w-md bg-white rounded-2xl p-6 shadow-xl m-4">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-gray-900">
            Email Verification Required
          </h3>
          
          <p className="text-gray-600">
            Please verify your email address to continue. Check your inbox for the verification link.
          </p>

          {resendStatus && (
            <div className={`p-3 rounded-xl text-sm ${
              resendStatus.type === 'success' 
                ? 'bg-green-50 text-green-700' 
                : 'bg-red-50 text-red-700'
            }`}>
              {resendStatus.message}
            </div>
          )}

          <div className="flex flex-col gap-3">
            <button
              onClick={handleResendVerification}
              disabled={isResending}
              className="w-full flex justify-center items-center py-3 px-4 rounded-xl text-white bg-[#800020] hover:bg-[#4A0012] disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
            >
              {isResending ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : (
                'Resend Verification Email'
              )}
            </button>
            
            <button
              onClick={onClose}
              className="w-full py-3 px-4 rounded-xl text-gray-700 bg-gray-100 hover:bg-gray-200 transition-all duration-200"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SignupPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    gender: '' // Added gender field
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const navigate = useNavigate();

  const features = [
    'Access to expert tailors',
    'Track your orders in real-time',
    'Schedule appointments at your convenience',
    'Personalized measurements profile',
    'Exclusive offers and updates'
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
  
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      return;
    }
  
    if (formData.password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }
  
    setIsLoading(true);
  
    try {
      // 1. Sign up the user
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            phone: formData.phone,
            email_verified: true,
            phone_verified: false,
            gender: formData.gender // Added gender to auth metadata
          }
        }
      });
  
      if (signUpError) throw signUpError;
  
      if (authData?.user) {
        // 2. Create entry in user_meta table
        const { error: metaError } = await supabase
          .from('user_meta')
          .insert([
            {
              id: authData.user.id,
              full_name: `${formData.firstName} ${formData.lastName}`.trim(),
              phone_number: formData.phone,
              email: formData.email,
              gender: formData.gender
            }
          ]);
  
        if (metaError) {
          console.error('Error creating user meta:', metaError);
          throw new Error('Failed to complete signup process');
        }
  
        // 3. Immediately sign in the user to get a session
        const { error: signInError } = await supabase.auth.signInWithPassword({
          email: formData.email,
          password: formData.password,
        });
  
        if (signInError) {
          if (signInError.code === 'email_not_confirmed') {
            setShowVerificationModal(true);
            return;
          }
          throw signInError;
        }
  
        // Clear form data
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          password: '',
          confirmPassword: '',
          gender: ''
        });
  
        // Navigate to dashboard after successful sign in
        navigate('/dashboard');
      }
    } catch (err) {
      console.error('Signup error:', err);
      if (err.code === 'email_not_confirmed') {
        setShowVerificationModal(true);
      } else {
        setError(err.message || 'An error occurred during signup');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (error) setError(null);
  };

  return (
    <div className="min-h-screen relative overflow-hidden bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <div className="absolute inset-0">
        <div className="absolute top-0 right-0 w-1/2 h-full bg-[#800020]/5 clip-path-diagonal" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-[#800020]/5 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-[#800020]/5 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2" />
      </div>

      <div className="min-h-screen flex flex-col lg:flex-row">
        {/* Left Panel - Benefits */}
        <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-[#800020] to-[#4A0012] p-12 relative overflow-hidden">
          <div className="relative z-10 flex flex-col justify-center text-white">
            <div className="flex items-center gap-3 mb-12">
              <div className="w-12 h-12 bg-white/10 rounded-xl flex items-center justify-center">
                <Scissors className="h-6 w-6 text-white" />
              </div>
              <h1 className="text-3xl font-bold">StitchKaro</h1>
            </div>
            
            <h2 className="text-4xl font-bold mb-8">Create your account</h2>
            <p className="text-xl text-white/80 mb-12">Join StitchKaro and experience premium tailoring services at your doorstep.</p>
            
            <div className="space-y-6">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                    <CheckCircle className="h-5 w-5 text-white" />
                  </div>
                  <p className="text-lg text-white/90">{feature}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Panel - Sign Up Form */}
        <div className="flex-1 flex items-center justify-center p-6">
          <div className="w-full max-w-md space-y-8 bg-white p-8 rounded-2xl shadow-xl relative">
            {error && (
              <div className="p-3 text-sm text-white bg-red-500 rounded-xl">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name Fields */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                  <div className="relative">
                    <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="John"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                  <div className="relative">
                    <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="Doe"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Gender Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              {/* Email Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                    placeholder="john@example.com"
                    required
                  />
                </div>
              </div>

              {/* Phone Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                <div className="relative">
                  <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                    placeholder="+91 1234567890"
                    required
                  />
                </div>
              </div>

{/* Password Fields */}
<div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="pl-10 pr-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="••••••••"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Confirm Password</label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="pl-10 pr-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="••••••••"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showConfirmPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#800020] transition-all duration-200 shadow-lg shadow-[#800020]/20"
              >
                {isLoading ? (
                  <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  'Create Account'
                )}
              </button>

              {/* Sign In Link */}
              <p className="text-center text-sm text-gray-600">
                Already have an account?{' '}
                <button
                  type="button"
                  onClick={() => navigate('/login')}
                  className="font-medium text-[#800020] hover:text-[#4A0012]"
                >
                  Sign in
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>

      {/* Email Verification Modal */}
      <EmailVerificationModal
        isOpen={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        email={formData.email}
      />

      <style jsx>{`
        .clip-path-diagonal {
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
        }
      `}</style>
    </div>
  );
};

export default SignupPage;