import { supabase } from '../config/client';

const getCurrentTimestamp = () => new Date().toISOString();

export const appointmentService = {
  // Create new appointment
  createAppointment: async (appointmentData) => {
    try {
      const { data, error } = await supabase
        .from('appointments')
        .insert([{
          user_id: appointmentData.user_id,
          consultant_id: appointmentData.consultant_id,
          services_id: appointmentData.services_id,
          address_id: appointmentData.address_id,
          is_bulk_measurement: appointmentData.is_bulk_measurement ?? false,
          number_of_people: appointmentData.number_of_people ?? 1,
          appointment_date: appointmentData.appointment_date,
          appointment_time: appointmentData.appointment_time,
          status: 'scheduled',
          consultation_fee: appointmentData.consultation_fee,
          notes: appointmentData.notes ?? null,
          name: appointmentData.name ?? null,
          created_at: getCurrentTimestamp(),
          updated_at: getCurrentTimestamp()
        }])
        .select(`
          *,
          service:services(*),
          address:addresses(*)
        `)
        .single();

      if (error) {
        console.error('Error creating appointment:', error.message);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error creating appointment:', error);
      return { data: null, error };
    }
  },

  // Get all appointments for a user
  getUserAppointments: async (userId) => {
    try {
      const { data, error } = await supabase
        .from('appointments')
        .select(`
          *,
          service:services(*),
          address:addresses(*)
        `)
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching appointments:', error.message);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error fetching appointments:', error);
      return { data: null, error };
    }
  },

  // Get single appointment by ID
  getAppointmentById: async (appointmentId) => {
    try {
      const { data, error } = await supabase
        .from('appointments')
        .select(`
          *,
          service:services(*),
          address:addresses(*)
        `)
        .eq('id', appointmentId)
        .single();

      if (error) {
        console.error('Error fetching appointment:', error.message);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error fetching appointment:', error);
      return { data: null, error };
    }
  },

  // Update appointment status
  updateAppointmentStatus: async (appointmentId, status) => {
    try {
      const { data, error } = await supabase
        .from('appointments')
        .update({ 
          status,
          updated_at: getCurrentTimestamp()
        })
        .eq('id', appointmentId)
        .select()
        .single();

      if (error) {
        console.error('Error updating appointment status:', error.message);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error updating appointment status:', error);
      return { data: null, error };
    }
  },

  // Cancel appointment
  cancelAppointment: async (appointmentId, reason) => {
    try {
      const { data, error } = await supabase
        .from('appointments')
        .update({ 
          status: 'cancelled',
          notes: reason,
          updated_at: getCurrentTimestamp()
        })
        .eq('id', appointmentId)
        .select()
        .single();

      if (error) {
        console.error('Error cancelling appointment:', error.message);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error cancelling appointment:', error);
      return { data: null, error };
    }
  },

  // Reschedule appointment
  rescheduleAppointment: async (appointmentId, newDate, newTime) => {
    try {
      const { data, error } = await supabase
        .from('appointments')
        .update({ 
          appointment_date: newDate,
          appointment_time: newTime,
          status: 'rescheduled',
          updated_at: getCurrentTimestamp()
        })
        .eq('id', appointmentId)
        .select()
        .single();

      if (error) {
        console.error('Error rescheduling appointment:', error.message);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error rescheduling appointment:', error);
      return { data: null, error };
    }
  },

  // Get available services
  getServices: async () => {
    try {
      const { data, error } = await supabase
        .from('services')
        .select('*')
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching services:', error.message);
        throw error;
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error fetching services:', error);
      return { data: null, error };
    }
  }
};
