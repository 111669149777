import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../config/client';
import { MessageSquare, Send, ArrowLeft } from 'lucide-react';

const MessagesPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchChats();
  }, [user, navigate]);

  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat.id);
      subscribeToMessages(selectedChat.id);
    }
  }, [selectedChat]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchChats = async () => {
    try {
      const { data, error } = await supabase
        .from('chats')
        .select('*')
        .eq('user_id', user.id);

      if (error) throw error;
      setChats(data || []);
    } catch (error) {
      console.error('Error fetching chats:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async (chatId) => {
    try {
      const { data, error } = await supabase
        .from('messages')
        .select('*')
        .eq('chat_id', chatId)
        .order('created_at', { ascending: true });

      if (error) throw error;
      setMessages(data || []);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const subscribeToMessages = (chatId) => {
    const subscription = supabase
      .channel(`messages:${chatId}`)
      .on('INSERT', payload => {
        setMessages(current => [...current, payload.new]);
      })
      .subscribe();

    return () => subscription.unsubscribe();
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !selectedChat) return;

    try {
      const { error } = await supabase
        .from('messages')
        .insert([
          {
            chat_id: selectedChat.id,
            user_id: user.id,
            content: newMessage,
            sender_type: 'user'
          }
        ]);

      if (error) throw error;
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
        <div className="w-16 h-16 border-4 border-[#800020] border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg border border-[#800020]/10 overflow-hidden">
          <div className="flex h-[calc(100vh-200px)]">
            {/* Chat List */}
            <div className={`w-full sm:w-80 border-r border-[#800020]/10 ${selectedChat ? 'hidden sm:block' : 'block'}`}>
              <div className="p-4 border-b border-[#800020]/10">
                <h1 className="text-2xl font-bold text-gray-900">Messages</h1>
              </div>
              <div className="overflow-y-auto h-[calc(100%-65px)]">
                {chats.map((chat) => (
                  <button
                    key={chat.id}
                    onClick={() => setSelectedChat(chat)}
                    className={`w-full p-4 flex items-start space-x-3 hover:bg-[#800020]/5 transition-colors ${
                      selectedChat?.id === chat.id ? 'bg-[#800020]/5' : ''
                    }`}
                  >
                    <div className="flex-shrink-0">
                      <MessageSquare className="h-10 w-10 text-[#800020]" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex justify-between">
                        <p className="text-sm font-medium text-gray-900">{chat.name}</p>
                        <p className="text-xs text-gray-500">
                          {new Date(chat.last_message_time).toLocaleTimeString([], { 
                            hour: '2-digit', 
                            minute: '2-digit' 
                          })}
                        </p>
                      </div>
                      <p className="text-sm text-gray-500 truncate">{chat.last_message}</p>
                    </div>
                    {chat.unread_count > 0 && (
                      <span className="inline-flex items-center justify-center w-5 h-5 text-xs font-medium text-white bg-[#800020] rounded-full">
                        {chat.unread_count}
                      </span>
                    )}
                  </button>
                ))}
                {chats.length === 0 && (
                  <div className="text-center p-4 text-gray-500">
                    No conversations yet
                  </div>
                )}
              </div>
            </div>

            {/* Chat Area */}
            {selectedChat ? (
              <div className="flex-1 flex flex-col">
                {/* Chat Header */}
                <div className="p-4 border-b border-[#800020]/10 flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <button 
                      className="sm:hidden text-gray-500 hover:text-gray-700"
                      onClick={() => setSelectedChat(null)}
                    >
                      <ArrowLeft className="h-6 w-6" />
                    </button>
                    <MessageSquare className="h-8 w-8 text-[#800020]" />
                    <h2 className="text-lg font-semibold text-gray-900">{selectedChat.name}</h2>
                  </div>
                </div>

                {/* Messages */}
                <div className="flex-1 overflow-y-auto p-4 space-y-4">
                  {messages.map((msg) => (
                    <div
                      key={msg.id}
                      className={`flex ${msg.sender_type === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-sm rounded-lg px-4 py-2 ${
                          msg.sender_type === 'user'
                            ? 'bg-[#800020] text-white'
                            : 'bg-gray-100 text-gray-900'
                        }`}
                      >
                        <p className="text-sm">{msg.content}</p>
                        <p className="text-xs mt-1 opacity-70">
                          {new Date(msg.created_at).toLocaleTimeString([], { 
                            hour: '2-digit', 
                            minute: '2-digit' 
                          })}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>

                {/* Input Area */}
                <div className="p-4 border-t border-[#800020]/10">
                  <form onSubmit={handleSendMessage} className="flex space-x-2">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:border-[#800020] focus:ring-[#800020] focus:outline-none"
                      placeholder="Type your message..."
                    />
                    <button
                      type="submit"
                      disabled={!newMessage.trim()}
                      className="px-4 py-2 bg-[#800020] text-white rounded-lg hover:bg-[#4A0012] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <Send className="h-5 w-5" />
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div className="hidden sm:flex flex-1 items-center justify-center">
                <div className="text-center">
                  <MessageSquare className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                  <p className="text-gray-500">Select a conversation to start messaging</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;