import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Navbar from '../components/Navbar';
import { useOrders } from '../services/ordersService ';
import {
  Scissors,
  Clock,
  Truck,
  CheckCircle,
  Phone,
  ArrowRight,
  Package,
  AlertCircle,
  CreditCard,
  Calendar,
  User
} from 'lucide-react';

const statusStyles = {
  pending: {
    text: 'Pending',
    icon: Clock,
    color: 'text-yellow-700',
    bg: 'bg-yellow-50',
    border: 'border-yellow-200',
    progress: 25
  },
  processing: {
    text: 'Processing',
    icon: Scissors,
    color: 'text-blue-700',
    bg: 'bg-blue-50',
    border: 'border-blue-200',
    progress: 50
  },
  out_for_delivery: {
    text: 'Out for Delivery',
    icon: Truck,
    color: 'text-purple-700',
    bg: 'bg-purple-50',
    border: 'border-purple-200',
    progress: 75
  },
  completed: {
    text: 'Completed',
    icon: CheckCircle,
    color: 'text-green-700',
    bg: 'bg-green-50',
    border: 'border-green-200',
    progress: 100
  }
};

const getStatusStyle = (status) => {
  return statusStyles[status?.toLowerCase()] || {
    text: status || 'Processing',
    icon: Clock,
    color: 'text-gray-700',
    bg: 'bg-gray-50',
    border: 'border-gray-200',
    progress: 25
  };
};

export default function Orders() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { orders, loading, error } = useOrders(user?.id);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF] flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-[#7D0321] border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
        <Navbar />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-white rounded-xl shadow-md p-12 text-center border border-red-200">
            <AlertCircle className="mx-auto h-12 w-12 text-red-500" />
            <h3 className="mt-4 text-lg font-medium text-gray-900">Error loading orders</h3>
            <p className="mt-2 text-sm text-gray-500">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header Section */}
        <div className="bg-[#7D0321] rounded-2xl shadow-xl">
          <div className="px-8 py-12 sm:px-12">
            <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4">
              My Orders
            </h1>
            <p className="text-xl text-white/90 max-w-2xl">
              Track and manage your orders all in one place.
            </p>
            <button
              onClick={() => navigate('/appointments')}
              className="mt-6 group inline-flex items-center px-6 py-3 bg-white text-[#7D0321] rounded-lg text-lg font-semibold shadow-md hover:shadow-lg transition-all duration-200 hover:bg-red-50"
            >
              Manage Appointments
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>

        {/* Orders List */}
        {orders.length > 0 ? (
          <div className="bg-white/80 rounded-xl shadow-md border border-[#7D0321]/10 p-8 mt-8">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-[#7D0321] to-[#7D0321] bg-clip-text text-transparent mb-8">
              Orders
            </h2>
            <div className="space-y-6">
              {orders.map((order) => {
                const status = getStatusStyle(order.status);
                const StatusIcon = status.icon;

                return (
                  <div
                    key={order.id}
                    className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    {/* Progress Bar */}
                    <div className="h-1 bg-gray-100 rounded-t-xl">
                      <div
                        className="h-1 bg-[#7D0321] rounded-t-xl transition-all duration-500"
                        style={{ width: `${status.progress}%` }}
                      />
                    </div>
                    <div className="p-6">
                      {/* Header */}
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          <Package className="h-8 w-8 text-[#7D0321]" />
                          <div>
                            <h2 className="text-lg font-semibold text-gray-900">
                              Order #{order.id.slice(0, 8)}
                            </h2>
                            <p className="text-sm text-gray-500">
                              {order.is_bulk_order ? 'Bulk Order' : 'Regular Order'}
                            </p>
                          </div>
                        </div>
                        <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${status.bg} ${status.color} ${status.border} border`}>
                          <StatusIcon className="mr-1.5 h-4 w-4" />
                          {status.text}
                        </span>
                      </div>

                      {/* Details Grid */}
                      <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-4">
                        <div className="flex flex-col">
                          <dt className="text-sm font-medium text-gray-500">Order Date</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            {new Date(order.created_at).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </dd>
                        </div>
                        <div className="flex flex-col">
                          <dt className="text-sm font-medium text-gray-500">Expected Delivery</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            {order.expected_delivery_date ? 
                              new Date(order.expected_delivery_date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              })
                              : 'To be confirmed'
                            }
                          </dd>
                        </div>
                        <div className="flex flex-col">
                          <dt className="text-sm font-medium text-gray-500">
                            <div className="flex items-center">
                              <User className="h-4 w-4 mr-1 text-[#7D0321]" />
                              Tailor
                            </div>
                          </dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            {order.tailor ? (
                              <div>
                                <span>{order.tailor.full_name}</span>
                                {order.tailor.phone_number && (
                                  <button 
                                    onClick={() => window.location.href = `tel:${order.tailor.phone_number}`}
                                    className="ml-2 text-[#7D0321] hover:text-[#5D0219]"
                                  >
                                    
                                  </button>
                                )}
                              </div>
                            ) : (
                              'To be assigned'
                            )}
                          </dd>
                        </div>
                        <div className="flex flex-col">
                          <dt className="text-sm font-medium text-gray-500">Total Amount</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            ₹{order.total_amount.toLocaleString('en-IN', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </dd>
                        </div>
                      </div>

                      {/* Payment Status and Actions */}
                      <div className="mt-6 flex items-center justify-between pt-6 border-t border-gray-100">
                        <div className="flex items-center">
                          <CreditCard className="h-4 w-4 text-[#7D0321] mr-2" />
                          <span className={`text-sm font-medium ${
                            order.payment_status === 'completed' ? 'text-green-600' : 'text-yellow-600'
                          }`}>
                            Payment {order.payment_status}
                          </span>
                        </div>
                        <div className="flex items-center space-x-3">
                          {order.tailor?.phone_number && (
                            <button 
                              onClick={() => window.location.href = `tel:${order.tailor.phone_number}`}
                              className="inline-flex items-center px-3 py-1.5 border border-[#7D0321] text-sm font-medium rounded-lg text-[#7D0321] hover:bg-[#7D0321]/10 transition-colors"
                            >
                              <Phone className="h-4 w-4 mr-1.5" />
                              Call Tailor
                            </button>
                          )}
                          <button
                            onClick={() => navigate(`/orders/${order.id}`)}
                            className="inline-flex items-center px-3 py-1.5 bg-[#7D0321] text-sm font-medium rounded-lg text-white hover:bg-[#5D0219] transition-colors"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="bg-white/80 rounded-xl shadow-md p-12 text-center border border-[#7D0321]/10 mt-8">
            <div className="mx-auto w-16 h-16 rounded-full bg-[#7D0321]/10 flex items-center justify-center">
              <Package className="h-8 w-8 text-[#7D0321]" />
            </div>
            <h3 className="mt-4 text-lg font-medium text-gray-900">No orders yet</h3>
            <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
              Get started by scheduling an appointment with our expert tailors for your custom clothing needs.
            </p>
            <div className="mt-6">
              <button
                onClick={() => navigate('/appointments')}
                className="inline-flex items-center px-4 py-2 bg-[#7D0321] text-white rounded-lg hover:bg-[#5D0219] transition-colors"
              >
                <Calendar className="h-4 w-4 mr-2" />
                Book Appointment
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}