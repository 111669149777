import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { Search, Heart } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const FavoritesPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    setLoading(false);
  }, [user, navigate]);

  const categories = [
    {
      id: 1,
      title: 'Designs',
      items: [
        {
          id: 1,
          type: 'design',
          title: 'Classic Suit Design',
          description: 'Three-piece suit with modern cut',
          date: '2024-01-15',
          image: '/api/placeholder/300/200',
        },
        {
          id: 2,
          type: 'design',
          title: 'Modern Jacket Design',
          description: 'Two-piece jacket with classic fit',
          date: '2024-01-10',
          image: '/api/placeholder/300/200',
        },
      ],
    },
    {
      id: 2,
      title: 'Fabrics',
      items: [
        {
          id: 3,
          type: 'fabric',
          title: 'Premium Italian Wool',
          description: 'High-quality wool fabric in navy blue',
          date: '2024-01-15',
          image: '/api/placeholder/300/200',
        },
        {
          id: 4,
          type: 'fabric',
          title: 'Luxury Silk',
          description: 'Soft and luxurious silk fabric in black',
          date: '2024-01-10',
          image: '/api/placeholder/300/200',
        },
      ],
    },
  ];

  const filteredFavorites = categories.flatMap(category =>
    category.items.filter(item =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
        <div className="w-16 h-16 border-4 border-[#800020] border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg border border-[#800020]/10 p-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Favorites</h1>
            <div className="relative w-full sm:w-auto">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search favorites..."
                className="w-full sm:w-auto pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:border-[#800020] focus:ring-[#800020] focus:outline-none"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="flex flex-wrap gap-2 mb-6">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                  selectedCategory === category.id
                    ? 'bg-[#800020] text-white'
                    : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                }`}
              >
                {category.title}
              </button>
            ))}
            <button
              onClick={() => setSelectedCategory(null)}
              className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                selectedCategory === null
                  ? 'bg-[#800020] text-white'
                  : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              }`}
            >
              All
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredFavorites
              .filter(item => !selectedCategory || item.type === categories.find(c => c.id === selectedCategory)?.items.find(i => i.id === item.id)?.type)
              .map((item) => (
                <div
                  key={item.id}
                  className="border border-[#800020]/10 rounded-xl overflow-hidden hover:shadow-lg transition-all duration-200"
                >
                  <div className="relative">
                    <img src={item.image} alt={item.title} className="w-full h-48 object-cover" />
                    <button 
                      className="absolute top-2 right-2 p-2 bg-white/90 rounded-full text-[#800020] hover:bg-white hover:text-[#4A0012] transition-colors duration-200"
                      aria-label="Remove from favorites"
                    >
                      <Heart className="h-5 w-5 fill-current" />
                    </button>
                  </div>
                  <div className="p-4">
                    <span className="inline-block text-xs font-medium text-[#800020] bg-[#800020]/10 px-2 py-1 rounded-full mb-2">
                      {item.type}
                    </span>
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">{item.title}</h3>
                    <p className="text-sm text-gray-500 mb-4">{item.description}</p>
                    <div className="flex justify-between items-center">
                      <span className="text-xs text-gray-500">Added {item.date}</span>
                      <button 
                        className="text-sm text-[#800020] hover:text-[#4A0012] font-medium transition-colors duration-200"
                        onClick={() => navigate(`/designs/${item.id}`)}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {filteredFavorites.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500">No favorites found matching your search criteria.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FavoritesPage;