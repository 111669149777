import React, { useState, useEffect } from 'react';
import {
  ArrowRight,
  ArrowLeft,
  Check,
} from 'lucide-react';
import { appointmentService } from '../services/appointmentService';
import AddressSelection from './AddressSelection';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-hot-toast';
import { supabase } from '../config/client';

function AppointmentBooking() {
  const { user } = useAuth();
  const [step, setStep] = useState(1);
  const [services, setServices] = useState([]);
  const [isLoadingServices, setIsLoadingServices] = useState(true);
  const [servicesError, setServicesError] = useState(null);
  const [formData, setFormData] = useState({
    service: '',
    date: '',
    time: '',
    address_id: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookingError, setBookingError] = useState('');

  const timeSlots = [
    '09:00 AM', '10:00 AM', '11:00 AM',
    '12:00 PM', '02:00 PM', '03:00 PM',
    '04:00 PM', '05:00 PM'
  ];

  useEffect(() => {
    fetchServices();
  }, []);

  const handleAddressSelect = (addressId) => {
    setFormData(prev => ({
      ...prev,
      address_id: addressId
    }));
    setBookingError('');
  };

  const fetchServices = async () => {
    try {
      setIsLoadingServices(true);
      setServicesError(null);

      const { data, error } = await supabase
        .from('services')
        .select('*, price')
        .order('created_at', { ascending: true });

      if (error) {
        throw error;
      }

      setServices(data || []);
    } catch (error) {
      console.error('Error fetching services:', error);
      setServicesError('Failed to load services. Please try again.');
    } finally {
      setIsLoadingServices(false);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setBookingError('');
  };

  const handleNext = () => {
    if (validateStep()) {
      setStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
    setBookingError('');
  };

  const handleSubmit = async () => {
    try {
      if (!user) {
        setBookingError('Please login to book an appointment');
        return;
      }

      setIsSubmitting(true);
      setBookingError('');

      const requiredFields = ['service', 'date', 'time', 'address_id'];
      const missingFields = requiredFields.filter(field => !formData[field]);
      
      if (missingFields.length > 0) {
        setBookingError('Please fill in all required fields');
        return;
      }

      const selectedService = services.find(s => s.id === formData.service);

      const timeStr = formData.time;
      const [time, period] = timeStr.split(' ');
      const [hours, minutes] = time.split(':');
      let hour = parseInt(hours);
      if (period === 'PM' && hour !== 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes}:00`;

      const appointmentData = {
        services_id: formData.service,
        appointment_date: formData.date,
        appointment_time: formattedTime,
        address_id: formData.address_id,  // Using the selected address ID
        user_id: user.id,
        consultant_id: 'e9b21243-1d34-482d-b054-9ef7ec65571a',
        is_bulk_measurement: false,
        number_of_people: 1,
        status: 'scheduled',
        consultation_fee: selectedService?.price || null,
        name: null,
        notes: null,
      };

      const {  error } = await appointmentService.createAppointment(appointmentData);

      if (error) {
        throw error;
      }

      toast.success('Appointment booked successfully!');
      setStep(4);
    } catch (error) {
      console.error('Error booking appointment:', error);
      toast.error(error.message || 'Failed to book appointment');
      setBookingError(error.message || 'Failed to book appointment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateStep = () => {
    switch (step) {
      case 1:
        return Boolean(formData.service);
      case 2:
        return Boolean(formData.date && formData.time);
      case 3:
        return Boolean(formData.address_id);
      default:
        return true;
    }
  };

  const renderProgressBar = () => (
    <div className="mb-8">
      <div className="flex items-center justify-between">
        {[1, 2, 3].map((index) => (
          <div key={index} className="flex items-center">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${step >= index ? 'bg-[#73001C] text-white' : 'bg-gray-100 text-gray-400'}`}>
              {step > index ? <Check className="h-5 w-5" /> : index}
            </div>
            {index < 3 && (
              <div className={`w-24 h-1 mx-2 ${step > index ? 'bg-[#73001C]' : 'bg-gray-100'}`} />
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-2">
        <span className="text-sm">Service</span>
        <span className="text-sm">Date & Time</span>
        <span className="text-sm">Address</span>
      </div>
    </div>
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Select a Service</h2>
            
            {servicesError && (
              <div className="p-4 rounded-lg bg-red-50 text-red-700 text-sm">
                {servicesError}
                <button 
                  onClick={fetchServices}
                  className="ml-2 text-red-700 hover:text-red-800 underline"
                >
                  Retry
                </button>
              </div>
            )}

            {isLoadingServices ? (
              <div className="flex items-center justify-center py-8">
                <div className="w-8 h-8 border-2 border-[#73001C] border-t-transparent rounded-full animate-spin" />
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {services.map((service) => (
                  <div
                    key={service.id}
                    onClick={() => handleInputChange('service', service.id)}
                    className={`p-4 rounded-lg border-2 cursor-pointer transition-all duration-200
                      ${formData.service === service.id 
                        ? 'border-[#73001C] bg-red-50' 
                        : 'border-gray-200 hover:border-gray-300'}`}
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="font-medium">{service.name}</h3>
                        <p className="text-sm text-gray-500">Duration: {service.duration}</p>
                      </div>
                      <span className="text-[#73001C] font-medium">{service.price}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Select Date & Time</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Date
                </label>
                <input
                  type="date"
                  value={formData.date}
                  onChange={(e) => handleInputChange('date', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Time
                </label>
                <div className="grid grid-cols-2 gap-2">
                  {timeSlots.map((slot) => (
                    <button
                      key={slot}
                      type="button"
                      onClick={() => handleInputChange('time', slot)}
                      className={`px-4 py-2 text-sm rounded-lg border transition-all duration-200
                        ${formData.time === slot
                          ? 'border-[#73001C] bg-red-50 text-[#73001C]'
                          : 'border-gray-200 hover:border-gray-300'}`}
                    >
                      {slot}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            {bookingError && (
              <div className="p-4 rounded-lg bg-red-50 text-red-700 text-sm">
                {bookingError}
              </div>
            )}
          <AddressSelection
  onAddressSelect={handleAddressSelect}
  selectedAddressId={formData.address_id}
/>
          </div>
        );

      case 4:
        return (
          <div className="text-center py-8">
            <div className="mx-auto w-16 h-16 rounded-full bg-green-100 flex items-center justify-center mb-4">
              <Check className="h-8 w-8 text-green-600" />
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">
              Appointment Confirmed!
            </h2>
            <p className="text-gray-500 mb-6">
              We've sent the confirmation details to your email.
            </p>
            <button
              onClick={() => window.location.href = '/appointments'}
              className="inline-flex items-center px-4 py-2 bg-[#73001C] text-white rounded-lg hover:bg-[#5a0016] transition-colors"
            >
              View My Appointments
              <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  if (!user) {
    return (
      <div className="max-w-3xl mx-auto py-8 px-4">
        <div className="p-4 rounded-lg bg-red-50 text-red-700 text-center">
          Please login to book an appointment.
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      {step < 4 && renderProgressBar()}
      
      {renderStepContent()}

      {step < 4 && (
        <div className="mt-8 flex justify-between">
          {step > 1 ? (
            <button
              type="button"
              onClick={handleBack}
              disabled={isSubmitting}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors disabled:opacity-50"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back
            </button>
          ) : (
            <div />
          )}
          
          {step === 3 ? (
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isSubmitting || !validateStep()}
              className={`inline-flex items-center px-4 py-2 rounded-lg transition-all duration-200
                ${validateStep()
                  ? 'bg-[#73001C] text-white hover:bg-[#5a0016]'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }
                disabled:opacity-50`}
            >
              {isSubmitting ? (
                <>
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                  Booking...
                </>
              ) : (
                <>
                  Confirm Booking
                  <ArrowRight className="ml-2 h-4 w-4" />
                </>
              )}
            </button>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              disabled={isSubmitting || !validateStep()}
              className={`inline-flex items-center px-4 py-2 rounded-lg transition-all duration-200
                ${validateStep()
                  ? 'bg-[#73001C] text-white hover:bg-[#5a0016]'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }
                disabled:opacity-50`}
            >
              {step === 2 ? 'Next' : 'Next'}
              <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default AppointmentBooking;
