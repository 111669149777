import React, { useState } from 'react';
import { Eye, EyeOff, Mail, Lock, Scissors } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../config/client';

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showResendButton, setShowResendButton] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const navigate = useNavigate();

  const handleResendConfirmation = async () => {
    setResendLoading(true);
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: email,
      });

      if (error) {
        setErrorMessage('Failed to resend confirmation email. Please try again.');
      } else {
        setErrorMessage('Confirmation email has been resent. Please check your inbox.');
        setShowResendButton(false);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setResendLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setShowResendButton(false);

    if (!email || !password) {
      setErrorMessage('Please fill in all fields');
      return;
    }

    setIsLoading(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        switch (error.code) {
          case 'email_not_confirmed':
            setErrorMessage('Please confirm your email before logging in');
            setShowResendButton(true);
            break;
          case 'invalid_credentials':
            setErrorMessage('Invalid email or password');
            break;
          default:
            setErrorMessage(error.message);
        }
      } else if (data?.user) {
        navigate('/dashboard');
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen relative overflow-hidden bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      {/* Decorative Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 right-0 w-1/2 h-full bg-[#800020]/5 clip-path-diagonal" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-[#800020]/5 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-[#800020]/5 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2" />
        
        {/* Decorative Pattern */}
        <div className="absolute inset-0 opacity-5">
          {[...Array(8)].map((_, i) => (
            <div
              key={i}
              className="absolute w-32 h-32 border border-[#800020] rounded-full"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                transform: `scale(${Math.random() * 2 + 0.5})`,
                opacity: Math.random() * 0.5 + 0.25
              }}
            />
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="min-h-screen flex flex-col lg:flex-row">
        {/* Left Panel - Decorative */}
        <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-[#800020] to-[#4A0012] p-12 relative overflow-hidden">
          <div className="relative z-10 flex flex-col justify-center text-white">
            <h1 className="text-5xl font-bold mb-6">Welcome to StitchKaro</h1>
            <p className="text-xl text-white/80 mb-8">Where timeless elegance meets modern craftsmanship</p>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                  <div className="w-3 h-3 rounded-full bg-white" />
                </div>
                <p className="text-white/80">Professional tailoring at your doorstep</p>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                  <div className="w-3 h-3 rounded-full bg-white" />
                </div>
                <p className="text-white/80">Track your orders in real-time</p>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                  <div className="w-3 h-3 rounded-full bg-white" />
                </div>
                <p className="text-white/80">Personalized styling assistance</p>
              </div>
            </div>
          </div>
          
          {/* Decorative Circles */}
          <div className="absolute -bottom-24 -right-24 w-96 h-96 border border-white/10 rounded-full" />
          <div className="absolute -bottom-12 -right-12 w-64 h-64 border border-white/10 rounded-full" />
        </div>

        {/* Right Panel - Login Form */}
        <div className="flex-1 flex items-center justify-center p-6">
          <div className="w-full max-w-md space-y-8 bg-white p-8 rounded-2xl shadow-xl relative">
            {/* Logo */}
            <div className="text-center mb-8">
              <div className="relative w-20 h-20 mx-auto mb-4">
                <div className="absolute inset-0 bg-gradient-to-r from-[#800020] to-[#4A0012] rounded-2xl">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Scissors className="h-10 w-10 text-white animate-scissors" />
                  </div>
                </div>
              </div>
              <h2 className="text-3xl font-bold text-[#800020]">Sign In</h2>
              <p className="mt-2 text-gray-600">Welcome back to StitchKaro</p>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="space-y-6">
              {errorMessage && (
                <div className="p-4 rounded-lg bg-red-50 text-red-700 text-sm">
                  <p>{errorMessage}</p>
                  {showResendButton && (
                    <button
                      type="button"
                      onClick={handleResendConfirmation}
                      disabled={resendLoading}
                      className="mt-2 text-sm font-medium text-[#800020] hover:text-[#4A0012] disabled:opacity-50"
                    >
                      {resendLoading ? 'Sending...' : 'Resend confirmation email'}
                    </button>
                  )}
                </div>
              )}

              {/* Email Input */}
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              {/* Password Input */}
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="pl-10 pr-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                    placeholder="••••••••"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Eye className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                </div>
              </div>

              {/* Remember Me and Forgot Password */}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-[#800020] focus:ring-[#800020]"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                    Remember me
                  </label>
                </div>
                <button type="button" className="text-sm font-medium text-[#800020] hover:text-[#4A0012]">
                  Forgot password?
                </button>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#800020] transition-all duration-200 shadow-lg shadow-[#800020]/20"
              >
                {isLoading ? (
                  <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  'Sign in'
                )}
              </button>

              {/* Sign Up Link */}
              <p className="text-center text-sm text-gray-600">
                Don't have an account?{' '}
                <button
                  type="button"
                  onClick={() => navigate('/signup')}
                  className="font-medium text-[#800020] hover:text-[#4A0012]"
                >
                  Sign up now
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes scissors {
          0%, 100% { transform: rotate(0deg) scale(1); }
          50% { transform: rotate(-10deg) scale(1.1); }
        }
        
        .animate-scissors {
          animation: scissors 2s ease-in-out infinite;
        }

        .clip-path-diagonal {
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
        }
      `}</style>
    </div>
  );
};

export default LoginPage;