import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import AppointmentBooking from '../components/AppointmentBooking';
import {
  Scissors,
  Clock,
  Truck,
  CheckCircle,
  BarChart3,
  MessageCircle,
  ArrowRight,
  Calendar,
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';

// Background Pattern Components
const BurgundyPattern = () => (
  <>
    <div className="fixed left-4 top-1/4 hidden xl:block">
      <div className="space-y-8">
        <div className="w-8 h-8 border-l-2 border-t-2 border-[#800020]/30 animate-[spin_4s_linear_infinite]" />
        <div className="w-8 h-8 border-l-2 border-b-2 border-[#800020]/20 animate-[spin_4s_linear_infinite_reverse]" />
        <div className="w-8 h-8 border-l-2 border-t-2 border-[#800020]/30 animate-[spin_4s_linear_infinite]" />
      </div>
    </div>
    <div className="fixed right-4 top-1/3 hidden xl:block">
      <div className="space-y-8">
        <div className="w-8 h-8 border-r-2 border-t-2 border-[#800020]/20 animate-[spin_4s_linear_infinite_reverse]" />
        <div className="w-8 h-8 border-r-2 border-b-2 border-[#800020]/30 animate-[spin_4s_linear_infinite]" />
        <div className="w-8 h-8 border-r-2 border-t-2 border-[#800020]/20 animate-[spin_4s_linear_infinite_reverse]" />
      </div>
    </div>
  </>
);

const CardPattern = () => (
  <div className="absolute inset-0 overflow-hidden opacity-10 pointer-events-none">
    <div className="absolute top-0 left-0 w-4 h-4 border-t-2 border-l-2 border-[#800020] animate-[spin_3s_linear_infinite]" />
    <div className="absolute top-0 right-0 w-4 h-4 border-t-2 border-r-2 border-[#800020] animate-[spin_3s_linear_infinite_reverse]" />
    <div className="absolute bottom-0 left-0 w-4 h-4 border-b-2 border-l-2 border-[#800020] animate-[spin_3s_linear_infinite_reverse]" />
    <div className="absolute bottom-0 right-0 w-4 h-4 border-b-2 border-r-2 border-[#800020] animate-[spin_3s_linear_infinite]" />
  </div>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [isAppointmentBookingVisible, setAppointmentBookingVisible] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const [activeOrders] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const { user } = useAuth();

  // Mock user data
 

  const processSteps = [
    {
      title: 'Book Appointment',
      description: 'Schedule a consultation with our expert tailors',
      icon: Calendar,
    },
    {
      title: 'Measurements',
      description: 'Get measured by our professional tailors',
      icon: Scissors,
    },
    {
      title: 'Stitching',
      description: 'Your garment is crafted with precision',
      icon: Truck,
    },
    {
      title: 'Delivery',
      description: 'Receive your perfectly fitted garment',
      icon: CheckCircle,
    },
  ];

  const handleBookingSuccess = () => {
    setAppointmentBookingVisible(false);
    setIsNewUser(false);
    // Add mock appointment
    const newAppointment = {
      id: Date.now(),
      date: new Date().toISOString(),
      status: 'scheduled'
    };
    setUpcomingAppointments([...upcomingAppointments, newAppointment]);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <BurgundyPattern />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
        {/* Welcome Banner */}
        <div className="relative overflow-hidden bg-gradient-to-r from-[#800020] to-[#4A0012] rounded-2xl shadow-xl">
          <div className="absolute inset-0 overflow-hidden opacity-30">
            <div className="absolute inset-0" style={{
              backgroundImage: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAgMjBMNDAgMjBNMjAgMjBMMjAgNDBNMjAgMjBMMCAyME0yMCAyMEwyMCAwIiBzdHJva2U9InJnYmEoMjU1LCAyNTUsIDI1NSwgMC4yKSIgc3Ryb2tlLXdpZHRoPSIwLjUiLz48L3N2Zz4=')",
              backgroundSize: "40px 40px"
            }} />
          </div>
          <div className="relative px-8 py-12 sm:px-12">
            <div className="relative z-10">
              <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4">
                {isNewUser 
                  ? `Welcome to StitchKaro, ${user?.user_metadata?.first_name}!` 
                  : `Welcome back, ${user?.user_metadata?.first_name}!`}
              </h1>
              <p className="text-xl text-white/90 max-w-2xl">
                {isNewUser
                  ? 'Begin your journey to perfectly tailored clothing. Our expert craftsmen are ready to bring your vision to life.'
                  : 'Track your orders and manage your appointments all in one place.'}
              </p>
              {isNewUser && (
                <button
                  onClick={() => setAppointmentBookingVisible(true)}
                  className="mt-6 group inline-flex items-center px-6 py-3 bg-white text-[#800020] rounded-xl text-lg font-semibold shadow-md hover:shadow-lg transition-all duration-200 hover:bg-gray-50"
                >
                  Book Appointment
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          {[
            { icon: BarChart3, title: 'Active Orders', value: activeOrders.length },
            { icon: Calendar, title: 'Upcoming', value: upcomingAppointments.length },
            { icon: MessageCircle, title: 'Support', value: '24/7 Available' }
          ].map((stat, index) => (
            <div key={index} className="relative overflow-hidden bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-200 border border-[#800020]/10 group">
              <CardPattern />
              <div className="relative z-10 p-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0 p-4 bg-gradient-to-br from-[#FDF8F8] to-white rounded-xl group-hover:from-[#800020]/5 group-hover:to-[#800020]/10 transition-colors duration-200">
                    <stat.icon className="h-8 w-8 text-[#800020]" />
                  </div>
                  <div className="ml-6">
                    <h3 className="text-lg font-semibold text-gray-900">{stat.title}</h3>
                    <p className="text-3xl font-bold mt-2 text-[#800020]">{stat.value}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* How It Works */}
        {isNewUser && (
          <div className="relative overflow-hidden bg-white rounded-xl shadow-lg p-8 border border-[#800020]/10">
            <CardPattern />
            <div className="relative z-10">
              <h2 className="text-2xl font-bold text-[#800020] mb-8">How It Works</h2>
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                {processSteps.map((step, index) => (
                  <div key={step.title} className="relative group">
                    {index < processSteps.length - 1 && (
                      <div className="hidden lg:block absolute top-12 left-full w-full border-t-2 border-dashed border-[#800020]/20 animate-pulse" />
                    )}
                    <div className="flex flex-col items-center text-center">
                      <div className="flex h-24 w-24 items-center justify-center rounded-xl bg-gradient-to-br from-[#FDF8F8] to-white group-hover:from-[#800020]/5 group-hover:to-[#800020]/10 transition-all duration-200">
                        <step.icon className="h-12 w-12 text-[#800020]" />
                      </div>
                      <h3 className="mt-6 text-xl font-semibold text-gray-900">{step.title}</h3>
                      <p className="mt-2 text-gray-600">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Recent Activity */}
        <div className="relative overflow-hidden bg-white rounded-xl shadow-lg border border-[#800020]/10">
          <CardPattern />
          <div className="relative z-10 p-8">
            <h2 className="text-2xl font-bold text-[#800020] mb-8">Recent Activity</h2>
            {activeOrders.length === 0 && upcomingAppointments.length === 0 ? (
              <div className="text-center py-16">
                <div className="bg-gradient-to-br from-[#FDF8F8] to-white rounded-xl p-6 w-24 h-24 mx-auto flex items-center justify-center">
                  <Clock className="h-12 w-12 text-[#800020]" />
                </div>
                <h3 className="mt-6 text-xl font-semibold text-gray-900">No Recent Activity</h3>
                <p className="mt-2 text-gray-600 max-w-md mx-auto">
                  Your recent orders and appointments will appear here once you start your journey with us
                </p>
                <button
                  onClick={() => setAppointmentBookingVisible(true)}
                  className="mt-6 group inline-flex items-center px-6 py-3 bg-[#800020] text-white rounded-xl text-lg font-semibold shadow-md hover:shadow-lg transition-all duration-200 hover:bg-[#4A0012]"
                >
                  Book Appointment
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            ) : (
              <div className="space-y-4">
                {upcomingAppointments.map((appointment) => (
                  <div key={appointment.id} className="p-4 border border-[#800020]/10 rounded-lg">
                    <p>Appointment Date: {new Date(appointment.date).toLocaleDateString()}</p>
                    <p>Status: {appointment.status}</p>
                  </div>
                ))}
                {activeOrders.map((order) => (
                  <div key={order.id} className="p-4 border border-[#800020]/10 rounded-lg">
                    <p>Order #{order.id}</p>
                    <p>Status: {order.status}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Appointment Booking Modal */}
        {isAppointmentBookingVisible && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-2xl w-full max-w-3xl p-6 m-4 relative">
              <button
                onClick={() => setAppointmentBookingVisible(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 p-2"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <AppointmentBooking 
                userId={user?.id} 
                onSuccess={handleBookingSuccess}
                onClose={() => setAppointmentBookingVisible(false)} 
              />
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .clip-path-diagonal {
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @keyframes spin-reverse {
          from {
            transform: rotate(360deg);
          }
          to {
            transform: rotate(0deg);
          }
        }
      `}</style>
    </div>
  );
};

export default Dashboard;